import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import styled from "styled-components"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo"

const Post = styled.article`
  margin: 64px 32px;

  h1 {
    margin-bottom: 1rem;
    text-transform: capitalize;
  }

  div:first-of-type {
    max-height: 400px;
  }

  p:first-of-type {
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    margin: 32px 16px;
  }
`

export default function BlogPostTemplate({ data }) {
  const {
    mdx: {
      frontmatter: { title, date, author, cover, description, seo },
      body,
    },
  } = data

  return (
    <Layout>
      <Seo title={title} description={description} meta={seo} />
      <Post>
        <div>
          <GatsbyImage image={getImage(cover)} alt={title} />
        </div>
        <h1>{title}</h1>
        <p>
          {author ? author : "Marialy Guedez"} &bull; {date}{" "}
        </p>
        <MDXRenderer>{body}</MDXRenderer>
      </Post>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        author
        description
        cover {
          childImageSharp {
            gatsbyImageData(
              webpOptions: { quality: 80 }
              layout: FULL_WIDTH
              quality: 70
            )
          }
        }
        seo {
          content
          property
        }
      }
      body
    }
  }
`
